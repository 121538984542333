import React from "react"
import { Carousel } from "react-responsive-carousel"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutCommunity from "../components/about-community"
import LearnMore from "../components/learn-more"
import Market from "../components/market"
import StandardGallery from "../components/standard-gallery"
import LearnBackground from "../images/community-learn-background.jpg"
import LearnImage from "../images/community-learn.jpg"
import parse from 'html-react-parser'

const Community = ({ data, location }) => {
  const community = data.communities

  const featuredProperties = data.allListingResidential.nodes.filter(p => p.ListOfficeMlsId === process.env.OFFICE_MLS_ID).slice(0, 10);
  const totalListings = 10 - featuredProperties.length;
  if (totalListings > 0) {
    featuredProperties.push(...data.allListingResidential.nodes.filter(p => p.ListOfficeMlsId !== process.env.OFFICE_MLS_ID).slice(0, totalListings));
  }

  return (
    <Layout>
      <Seo title={community.name} location={location} />
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={100}
        showArrows={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={true}
        stopOnHover={false}
        interval={100000}
        transitionTime={1000}
      >
        {community.gallery.media.map(media => {
          return (
            <>
              <img
                key={media.id}
                className="cha-h__carousel__img"
                alt={community.name}
                src={
                  process.env.MEDIA_CACHE_URL_PREPEND.replace(
                    "WIDTH",
                    1920
                  ).replace("HEIGHT", 1920 / 1.78) + media.url
                }
              />
              <div className="lower-slider-gradient"></div>
            </>
          )
        })}
      </Carousel>
      <AboutCommunity community={community} />
      <LearnMore
        backgroundImage={
          community.centerBladeBackgroundImage
            ? community.centerBladeBackgroundImage.url
            : LearnBackground
        }
        image={
          community.centerBladeForegroundImage
            ? community.centerBladeForegroundImage.url
            : LearnImage
        }
        title={community.centerBladeTitle}
        description={community.centerBladeContent}
        to={community.centerBladeCTA ? community.centerBladeCTA.href : ""}
      />
      <Market
        marketReportHeader={"Market Report"}
        communityName={community.name.toUpperCase().replace(" ", "_")}
        marketReportLearnMoreHref={data.homeContent.marketreportlink.href}
      />
      {featuredProperties.length > 0 && (
        <div className="standard-gallery-container">
          <h1 className=" h1-header">Featured Properties</h1>
          <StandardGallery
            posts={featuredProperties}
            communityName={community.name}
          />
        </div>
      )}
    </Layout>
  )
}

export default Community

export const query = graphql`
  query($id: String!, $community: String!) {
    communities(id: { eq: $id }) {
      name
      description
      image {
        url
      }
      gallery {
        media {
          url
        }
      }
      centerBladeBackgroundImage {
        url
      }
      centerBladeCTA {
        href
      }
      centerBladeContent
      centerBladeForegroundImage {
        url
      }
      centerBladeTitle
    }
    allListingResidential(sort: {fields: ListPrice, order: DESC}, filter: { StandardStatus: {ne: "Closed"}, MLSAreaMajor: { eq: $community } }) {
      nodes {
        UnparsedAddress
        ListPrice
        BedroomsTotal
        BathroomsTotalInteger
        PrefferedPhoto
        ListOfficeMlsId
        LotSizeAcres
        BuildingAreaTotal
        LivingArea
        LotSizeSquareFeet
        ListingId
        parsedAddress
        path
      }
    }
    homeContent {
      marketreportlink {
        href
      }
    }

  }
`

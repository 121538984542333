import React from "react"
import { Link } from "gatsby"
import "./marker.css"

const LearnMore = ({
  backgroundImage,
  image,
  title,
  description,
  to,
  reverse
}) => {
  const style = {
    backgroundImage: `url("${backgroundImage}")`
  }

  return (
    <div style={style} className="cha-learn__container">
      {reverse ? (
        <div className="containers">
          <div className={`cha-learn__container__right cha-learn__container__text-position__reverse`}>
            <h1 className="h1-header">{title}</h1>
            <p className="body1" dangerouslySetInnerHTML={{ __html: description }}></p>
            <Link to={to} className="cha-learn__container__right__link">
              LEARN MORE
            </Link>
          </div>
          <img
            className={`cha-learn__container__inner cha-learn__container__image-position__reverse`}
            alt="Sell you home"
            src={image}
          />
        </div>
      ) : (
        <div className="containers">
          <img
            className={`cha-learn__container__inner cha-learn__container__image-position`}
            alt="Sell you home"
            src={image}
          />
          <div className={`cha-learn__container__right cha-learn__container__text-position`}>
            <h1 className="h1-header">{title}</h1>
            <p className="body1" dangerouslySetInnerHTML={{ __html: description }}></p>
            <Link to={to} className="cha-learn__container__right__link">
              LEARN MORE
            </Link>
          </div>
        </div>
      )}

    </div>
  )
}

export default LearnMore

import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./gallery.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageSlide = props => {
  return (
    <div
      className={props.classNames + "  mx-auto slide-container"}
      index={props.index}
    >
      {props.index === props.activeSlide ? (
        <Link to={props.propertyPath}>
          <LazyLoadImage 
            alt="" className="s-gallery-image" 
            src={props.source} 
          />
        </Link>
      ) : (
        <LazyLoadImage 
            alt="" className="s-gallery-image" 
            src={props.source} 
          />
      )}

      <div className={props.captionClass + " legend"}>
        <div className="specific-item">
          <StaticImage src="../images/tag-i.png" alt="beds" />
          <span>${new Intl.NumberFormat().format(props.pricing)}</span>
        </div>
        <div className="specific-item">
          <StaticImage src="../images/location-i.png" alt="beds" />
          <span className="span-blue info">{props.location}</span>
        </div>
        <StaticImage src="../images/bed-i.png" alt="beds" />
        <span className="span-blue info">{props.bed}</span>
        <StaticImage src="../images/bath-i.png" alt="beds" />
        <span className="span-blue info">{props.bath}</span>
        <StaticImage src="../images/area-i.png" alt="beds" />
        <span className="span-blue info">{new Intl.NumberFormat().format(props.sqft)} SF</span>
      </div>
    </div>
  )
}

export default ImageSlide

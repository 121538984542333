import React, { useState } from "react"
import parse from "html-react-parser"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { FaCircleNotch } from "react-icons/fa"
import axios from "axios"
import ContactSchema from "../helpers/contact-schema"
import { Formik, Form, Field, ErrorMessage } from "formik"

const AboutProperty = ({ community }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [response, setResponse] = useState("")
  const [creatingLeadAjax, setCreatingLeadAjax] = useState(false)

  return (
    <div className="cha-about-community__about">
      <div className="cha-about-community__about__inner">
        <div className="cha-about-community__about__inner__left">
          <h1 className="cha-about-community__about__inner__left__title h1-header">
            {community.name}
          </h1>
          <p className="cha-about-community__about__inner__left__body body1">
            {parse(community.description)}
          </p>
        </div>
        <div className="cha-about-community__about__inner__right">
          <p className="cha-about-community__about__inner__right__title">
            GET MORE INFO
          </p>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              message: "",
            }}
            validationSchema={ContactSchema}
            onSubmit={async values => {
              setCreatingLeadAjax(true)

              if (!executeRecaptcha) {
                setResponse("Recaptcha Issue, Try Again Later...")
                setCreatingLeadAjax(false)

                return
              }

              try {
                const recaptchaToken = await executeRecaptcha(
                  "non_exclusive_listing"
                )
                await axios({
                  url: process.env.FOUNDATION_LEAD_CREATE_ENDPOINT,
                  method: "POST",
                  data: {
                    recaptchaToken,
                    formValues: values,
                    propertyInfo: {
                      createdFrom: `About Community - ${community.name}`,
                    },
                  },
                })
                setResponse(
                  `Your contact was sent to us, we'll get in touch briefly with you!`
                )
              } catch (error) {
                setResponse(
                  `Sorry, there was an issue sending your contact request...`
                )
              }

              setCreatingLeadAjax(false)
            }}
          >
            {({ handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                className="cha-about-community__about__inner__right__form"
                name="contact community"
                method="POST"
              >
                <Field name="firstName" placeholder="FIRST NAME" />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
                <Field name="lastName" placeholder="LAST NAME" />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
                <Field name="email" placeholder="EMAIL ADDRESS" />
                <ErrorMessage
                  name="email"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
                <Field name="phone" placeholder="PHONE NUMBER" />
                <ErrorMessage
                  name="phone"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />
                <Field
                  as="textarea"
                  rows="5"
                  name="message"
                  placeholder="MESSAGE"
                ></Field>
                <ErrorMessage
                  name="message"
                  component="div"
                  render={msg => <div className="error">*</div>}
                />

                <button
                  aria-disabled={creatingLeadAjax}
                  disabled={creatingLeadAjax}
                  className="cha-about-community__about__inner__right__button"
                  type="submit"
                >
                  {creatingLeadAjax ? (
                    <FaCircleNotch className="cha-cl__spin-icon" />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
                {response && <span className="response-white">{response}</span>}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AboutProperty

import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ImageSlide from "./image-slide"
import { Link } from "gatsby"

const StandardGallery = ({ posts, communityName }) => {
  const [state, setState] = useState({
    activeSlide: 0,
  })

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    lazyLoad: false,
    focusOnSelect: true,
    arrows: false,
    variableWidth: true,
    className: "slider variable-width",
    beforeChange: (current, next) => setState({ activeSlide: next }),
    responsive: [
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: true,
          centerMode: true,
        },
      },
    ],
  }

  return (
    <div>
      <Slider {...settings}>
        {posts.map((item, index) => {
          return (
            <ImageSlide
              source={item.PrefferedPhoto.replace('WIDTH', 1200).replace('HEIGHT', 1200 / 1.5)}
              placeholder={item.PrefferedPhoto.replace('WIDTH', 200).replace('HEIGHT', 200 / 1.5)}
              classNames={
                (index === state.activeSlide
                  ? "w-full"
                  : "w-10/12 opacity-50 custom-margin") +
                " text-center justify-items-center"
              }
              index={index}
              captionClass={
                index !== state.activeSlide ? "hidden" : "inline-block"
              }
              key={Math.random() * 100000 - 1 + 1}
              location={item.UnparsedAddress}
              pricing={item.ListPrice}
              bed={item.BedroomsTotal}
              bath={item.BathroomsTotalInteger}
              sqft={item.LivingArea}
              ListingId={item.ListingId}
              parsedAddress={item.parsedAddress}
              propertyPath={item.path}
              activeSlide={state.activeSlide}
            ></ImageSlide>
          )
        })}
      </Slider>
      <Link className="cha-h__featured__container__view-all button-dark-gray" to={`/places${communityName ? `?community=${communityName}` : ''}`}>
        VIEW ALL
      </Link>
    </div>
  )
}

export default StandardGallery

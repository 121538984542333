/* eslint-disable jsx-a11y/no-onchange */

import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { isEmpty } from "lodash"

const Market = ({ marketReportContent, marketReportHeader, marketReportSubheader, communityName, marketReportLearnMoreHref }) => {
  const [community, setCommunity] = useState(communityName || "ASPEN")

  const data = useStaticQuery(graphql`
    query QueryMarket {
      market {
        ASPEN_communityAverageSalesPrice
        ASPEN_closedTotalSales
        ASPEN_communitySalesCount
        ASPEN_pricePerSquareTotal
        ASPEN_propertyWithZeroSquareFoot
        ASPEN_totalActiveListing
        ASPEN_totalNewProperties
        ASPEN_totalPendingListing
        ASPEN_pricePerSquareFoot
        SNOWMASS_VILLAGE_closedTotalSales
        SNOWMASS_VILLAGE_communityAverageSalesPrice
        SNOWMASS_VILLAGE_communitySalesCount
        SNOWMASS_VILLAGE_pricePerSquareTotal
        SNOWMASS_VILLAGE_propertyWithZeroSquareFoot
        SNOWMASS_VILLAGE_totalActiveListing
        SNOWMASS_VILLAGE_totalNewProperties
        SNOWMASS_VILLAGE_totalPendingListing
        SNOWMASS_VILLAGE_pricePerSquareFoot
        WOODY_CREEK_closedTotalSales
        WOODY_CREEK_communityAverageSalesPrice
        WOODY_CREEK_communitySalesCount
        WOODY_CREEK_pricePerSquareFoot
        WOODY_CREEK_pricePerSquareTotal
        WOODY_CREEK_propertyWithZeroSquareFoot
        WOODY_CREEK_totalActiveListing
        WOODY_CREEK_totalNewProperties
        WOODY_CREEK_totalPendingListing
        OLD_SNOWMASS_closedTotalSales
        OLD_SNOWMASS_communityAverageSalesPrice
        OLD_SNOWMASS_communitySalesCount
        OLD_SNOWMASS_pricePerSquareFoot
        OLD_SNOWMASS_pricePerSquareTotal
        OLD_SNOWMASS_propertyWithZeroSquareFoot
        OLD_SNOWMASS_totalActiveListing
        OLD_SNOWMASS_totalPendingListing
        OLD_SNOWMASS_totalNewProperties
        BASALT_closedTotalSales
        BASALT_communityAverageSalesPrice
        BASALT_communitySalesCount
        BASALT_pricePerSquareTotal
        BASALT_propertyWithZeroSquareFoot
        BASALT_totalActiveListing
        BASALT_totalNewProperties
        BASALT_totalPendingListing
        BASALT_pricePerSquareFoot
        MISSOURI_HEIGHTS_communitySalesCount
        MISSOURI_HEIGHTS_communityAverageSalesPrice
        MISSOURI_HEIGHTS_closedTotalSales
        MISSOURI_HEIGHTS_pricePerSquareFoot
        MISSOURI_HEIGHTS_pricePerSquareTotal
        MISSOURI_HEIGHTS_propertyWithZeroSquareFoot
        MISSOURI_HEIGHTS_totalActiveListing
        MISSOURI_HEIGHTS_totalPendingListing
        MISSOURI_HEIGHTS_totalNewProperties
        CARBONDALE_closedTotalSales
        CARBONDALE_communityAverageSalesPrice
        CARBONDALE_communitySalesCount
        CARBONDALE_pricePerSquareTotal
        CARBONDALE_propertyWithZeroSquareFoot
        CARBONDALE_totalActiveListing
        CARBONDALE_totalNewProperties
        CARBONDALE_totalPendingListing
        CARBONDALE_pricePerSquareFoot
        GLENWOOD_closedTotalSales
        GLENWOOD_communityAverageSalesPrice
        GLENWOOD_communitySalesCount
        GLENWOOD_pricePerSquareFoot
        GLENWOOD_pricePerSquareTotal
        GLENWOOD_propertyWithZeroSquareFoot
        GLENWOOD_totalNewProperties
        GLENWOOD_totalActiveListing
        GLENWOOD_totalPendingListing
      }
    }
  `)

  const commarize = (number, min) => {
    min = min || 1e3

    if (number >= min) {
      const units = ["k", "M", "B", "T"]

      const order = Math.floor(Math.log(number) / Math.log(1000))

      const unitname = units[order - 1]
      const num = Math.floor((number * 10) / 1000 ** order)

      return num / 10 + unitname
    }

    return number.toFixed(1).toLocaleString()
  }

  return (
    <div className="cha-market__container">
      <h1 className="cha-market__container__title h1-header">{marketReportHeader}</h1>
      {isEmpty(communityName) ? (
        <>
          <p className="cha-market__container__area subhead">
            {marketReportSubheader}
          </p>
          <div className="select-container">
            <select onChange={e => setCommunity(e.target.value)}>
              <option selected value="ASPEN"> Aspen </option>
              <option value="SNOWMASS_VILLAGE">Snowmass Village</option>
              <option value="WOODY_CREEK">Woody Creek</option>
              <option value="OLD_SNOWMASS">Old Snowmass</option>
              <option value="BASALT">Basalt</option>
              <option value="MISSOURI_HEIGHTS">Missouri Heights</option>
              <option value="CARBONDALE">Carbondale</option>
              <option value="GLENWOOD">Glenwood Springs</option>
            </select>
          </div>
        </>
      ) : null}

      <div className="cha-market__container__inner containers">
        <div className="cha-market__container__row-container">
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {data.market[`${community}_communitySalesCount`]}
            </div>
            <div className="bar"></div>
            <div>Sales number for the last 12 months</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              $
              {commarize(
                data.market[`${community}_communityAverageSalesPrice`]
              )}
            </div>
            <div className="bar"></div>
            <div>Average sales price for the last 12 months</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              $
              {new Intl.NumberFormat().format(
                data.market[`${community}_pricePerSquareFoot`]
              )}
            </div>
            <div className="bar"></div>
            <div>Price per square foot for the last 12 months</div>
          </div>
        </div>
        <div className="cha-market__container__row-container">
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {data.market[`${community}_totalActiveListing`]}
            </div>
            <div className="bar"></div>
            <div>Current listings</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {data.market[`${community}_totalPendingListing`]}
            </div>
            <div className="bar"></div>
            <div>Properties under contract</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {data.market[`${community}_totalNewProperties`]}
            </div>
            <div className="bar"></div>
            <div>New properties on the market</div>
          </div>
        </div>
      </div>
      <div className="market-sub-info">
        {marketReportContent ? 
          <div>
            {marketReportContent}
          </div>
          :
          ''
        }
        
        <a href={marketReportLearnMoreHref} target="_blank">Learn More</a>
      </div>
        <div className="market-gradient"></div>
    </div>
  )
}

export default Market
